<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} survey group</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense
                  :error="$v.survey_group.title.$error"
                  v-model="survey_group.title">
                <template v-slot:label>
                 Survey group title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
              <span class="text-danger" v-if="$v.survey_group.title.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label>Description</label>
              <span class="text-danger">*</span>
              <ckeditor :config="editorConfig" :error="$v.survey_group.description.$error"
                  v-model="survey_group.description" >
              </ckeditor>
            </v-col>

            <v-col cols="6" md="4">
              Status
              <v-switch v-model="survey_group.is_active" :label="survey_group.is_active ? 'Active' : 'Inactive' "
                  color="primary">
              </v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text x-large @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn x-large dark :loading="loading"
               @click="createOrUpdate()">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";

import {required} from "vuelidate/lib/validators";


const surveyGroup=new SurveyGroupService();
export default {
  validations: {
    survey_group: {
      title: {required},
      description: {},

    }
  },
  data(){
    return{
      loading:false,
      errors: [],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      dialog:false,
      edit:false,
      title:'Create',
      survey_group:{
        title:null,
        description:null,
        is_active:1,
      },
      categories:[],
      categoryLoading:true,
    }
  },
  computed:{

  },
  methods:{

    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    resetForm(){
      this.survey_group={
        title:null,
        is_active:1,
        description:null,
      }
    },

    createSurveyGroup() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";

    },
    editSurveyGroup(item) {
      this.openDialog();
      this.edit = true;
      this.survey_group = item;
      this.title = "Edit";

    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {

        if (!this.edit) this._create(this.survey_group);
        else this.update(this.survey_group);
      }
    },

    _create: function (data) {
      this.loading = true;
      surveyGroup
          .create(data)
          .then(response => {
            this.$snotify.success("Survey group added");
            this.resetForm();
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    update: function (data) {
      this.loading = true;
      surveyGroup
          .update(this.survey_group.id, data)
          .then(response => {
            this.$snotify.success("Survey group updated");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');

          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
  }
}
</script>